
/*jshint esversion: 6 */


const htmlDecode = (input) => {
    const e = document.createElement('textarea');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

(function() {
  
  // menu accordion
  var accordionHeaders = document.querySelectorAll('[data-accordion-header]');
  for (var a = 0; a < accordionHeaders.length; a++) {
      var accordionHeader = accordionHeaders[a]; 
      accordionHeader.addEventListener('click', function(e){
        var target = this.parentElement.nextElementSibling;
        var expanded = this.getAttribute('aria-expanded') === 'true' || false;
        
            e.preventDefault();
            this.setAttribute('aria-expanded', !expanded);
            target.hidden = expanded; 
            if(!expanded) {}          
    });      
  }


  // menu toggle
  var menu = document.querySelector('#menu');
  var body = document.body;
  var menubutton = document.querySelector('#menubutton');
  var overlay = document.querySelector('#menu-overlay');

  menubutton.addEventListener('click', function(){
      menu.classList.toggle('visible');
      body.classList.toggle('menu-opened');
  });
  overlay.addEventListener('click', function(){
      menu.classList.toggle('visible');
      body.classList.toggle('menu-opened');
  });

  
  // plyr players
  var players = document.querySelectorAll(".player");
  if(players.length){
      for(var p = 0; p< players.length; p++) {
        var pstr = players[p].dataset.poster != undefined ? players[p].dataset.poster : null;
        if(players[p].dataset.poster != undefined ){
            players[p].querySelector('iframe').setAttribute('poster', pstr);
        }
        var videoplayer = new Plyr(players[p], {
            poster: pstr
        });
        videoplayer.on('ready', (event) => {
            const instance = event.detail.plyr;
            // console.log(instance);
        });
          
      }
  }

  var audios = document.querySelectorAll(".audio");
  if(audios.length){
      for(var ai = 0; a< audios.length; a++) {
          var audiplayer = new Plyr(audios[a], {
              // controls: ['play-large']
          });
      }
  }

  // slideshows
  
  var elems = document.querySelectorAll('.slideshow');
  if(elems.length){
      for(var s = 0; s< elems.length; s++) {
          var slideshow = new Flickity( elems[s], {
              cellSelector: 'figure',
              pageDots: false,
              lazyLoad: true,
              wrapAround: true,
              autoPlay: 3000
          });
      }
  }

  var homeslides = document.querySelectorAll('.home-slideshow');
  if(homeslides.length){
      for(var i = 0; i< homeslides.length; i++) {
          var homeslideshow = new Flickity( homeslides[i], {
              cellSelector: '.home-block',
              pageDots: false,
              cellAlign: 'left',
              wrapAround: true,
              autoPlay: 3000
          });
      }
  }

  // map territoire
  var territoire = document.querySelector('.map-container');
  if(territoire){
      var cities = document.querySelector('.cities');
      var svg = territoire.querySelector('svg');
      var circles = svg.querySelectorAll('circle');
      
      cities.addEventListener('mouseover', event => {
          if (event.target.matches('a')) {
              var hash = event.target.getAttribute('href').replace("spectacles-", "");
              var path = svg.querySelector(hash);
              path.setAttribute('r', 15);
          }
      },true);

      cities.addEventListener('mouseout', event => {
          for (var index = 0; index < circles.length; index++) {
              circles[index].setAttribute('r', 7.5);                   
          }
      },true);

      svg.addEventListener('mouseover', event => {
          if (event.target.matches('rect')) {
              var circle = event.target.nextElementSibling;
              circle.setAttribute('r', 15);
          }
          if (event.target.matches('circle')) {
              event.target.setAttribute('r', 15);
          }
      },true);

      svg.addEventListener('mouseout', event => {
          for (var index = 0; index < circles.length; index++) {
              circles[index].setAttribute('r', 7.5);                   
          }
      },true);
  }


  // map infos
  var map = document.querySelector('#map');
  if(map){
      //var mapboxgl = require('mapbox-gl');

      mapboxgl.accessToken = 'pk.eyJ1IjoibG9sYWR1dmFsIiwiYSI6ImNrMG1pbHgxZTE0NTgzbG40eDY4MGpoZnEifQ.50zIb4OUxBl7htWKEcaHzg';
      var myinfosmap = new mapboxgl.Map({
          container: 'map',
          style: 'mapbox://styles/loladuval/ck7xlombd10rj1imhengnqd93',
          center: [3.6978596448898, 43.409618894393],
          zoom:14
      });
      
      var el = document.createElement('span');
      el.className = "tms-marker";
      var marker = new mapboxgl.Marker(el)
      .setLngLat([3.6978596448898, 43.409618894393])
      .addTo(myinfosmap);
  }

  // map bonnes adresses
  var addresses_map = document.querySelector('#addresses');
  if(addresses_map){
      //var mapboxgl = require('mapbox-gl');
      var bounds = new mapboxgl.LngLatBounds();
      mapboxgl.accessToken = 'pk.eyJ1IjoibG9sYWR1dmFsIiwiYSI6ImNrMG1pbHgxZTE0NTgzbG40eDY4MGpoZnEifQ.50zIb4OUxBl7htWKEcaHzg';
      var myaddresses_map = new mapboxgl.Map({
          cooperativeGestures: true,
          container: 'addresses',
          style: 'mapbox://styles/loladuval/ck7xlombd10rj1imhengnqd93',
          center: [3.6978596448898, 43.409618894393],
          zoom:14
      });
      
      
      if(addresses){
          for (let i = 0; i < addresses.length; i++) {
            const address = addresses[i];
            let el = document.createElement('span');
            el.className = "marker marker-" + address.category_slug;
            el.id = "marker-" + address.slug;
            el.style.setProperty('--accentcolor', address.accentcolor);
            var addresses_marker = new mapboxgl.Marker(el)
            .setLngLat(address.longlat)
            .addTo(myaddresses_map);
            bounds.extend(address.longlat);
            addresses_marker.setPopup(
                new mapboxgl.Popup({ offset: 25 }) 
                .setHTML(
                  '<div class="map-pop">' +
                    '<div class="map-pop-header" style="background:'+ address.accentcolor +'">' +
                      address.category+
                    '</div>' +
                    '<div class="map-pop-content">' +
                      "<strong>" + address.title +  "</strong><br>" +
                      htmlDecode(address.address) +
                      "<br><a href='#" + address.slug + "'>En savoir plus</a>" +
                    '</div>' +
                  '</div>')
                );
          }
          myaddresses_map.fitBounds(bounds, { padding: 100 });
      }
  }

  // home calendar
  var calendar = document.querySelector('#calendar');
  if(calendar){
      var sb = new SimpleBar(calendar);
  }

  // is merci (newsletter)
  var hashash = window.location.hash;
  if(hashash){
      if(hashash == "#merci"){
          var main = document.querySelector("main");
          var merci = document.createElement('div');
          merci.id = "merci";
          var par = document.createElement('p');
          par.textContent = "Merci de vous être abonné·e à notre newsletter.";
          var btn = document.createElement('button');
          btn.textContent = "×";
          merci.appendChild(par);
          merci.appendChild(btn);            
          main.insertBefore(merci, main.firstChild);
          merci.addEventListener("click", function(){
              merci.parentNode.removeChild(merci);
              window.location.hash = "";
          });
      }
  }

  
  // plus link
  
  if(typeof scrolltoplus !== 'undefined') {
      // Scroll to a certain element
      document.querySelector('.grid li:nth-child(' + scrolltoplus + ')').scrollIntoView({ 
          behavior: 'smooth' 
      });
  }

  // show tabs
  function showSection(hash, link){
      var cible = document.querySelector(hash);
      var visibles = document.querySelectorAll('.visible');
      var activelinks = document.querySelectorAll('a.active');

      // click
      if(link != null){
          // h4
          if(link.matches('.h4-show-show-section')){
            var links = document.querySelectorAll("[href='" + hash + "']");
            for (var il = 0; il < links.length; il++) {
                links[il].classList.toggle('active');
            }
            cible.classList.toggle('visible');
          } 
          // show-nav
          else {
              for (var iv = 0; iv < visibles.length; iv++) {
                  visibles[iv].classList.remove('visible');
              }
              for (var ij = 0; ij < activelinks.length; ij++) {
                  activelinks[ij].classList.remove('active');
              }
              link.classList.add('active');
              cible.classList.add('visible');
          }
      } 

      // popstate
      else {
          for (var ip = 0; ip < visibles.length; ip++) {
              visibles[ip].classList.remove('visible');
          }
          for (var iq = 0; iq < activelinks.length; iq++) {
              activelinks[iq].classList.remove('active');
          }
          var linkslinks = document.querySelectorAll("[href='" + hash + "']");
          
          for (var is = 0; is < linkslinks.length; is++) {
            linkslinks[is].classList.add('active');
          }
          cible.classList.toggle('visible');
      }
  }

  var shownav = document.querySelector(".main-show");
  if(shownav){
      shownav.addEventListener('click', event => {
          var link = event.target;
          if (link.matches('.show-link')) {
              // cas du spectacle depuis la page Production
              window.location = link.getAttribute('href');
          } else if (link.matches('.show-show-section')) {          
              // cas général (depuis la page Spectacle)
              event.preventDefault();
              event.stopPropagation();
              var hash = link.getAttribute('href');
              history.pushState({}, window.title, hash);
            console.log(link);
              showSection(hash, link);
          }
      });
  }

  // show specials
  var tpl = document.body.getAttribute("data-template");
  if(tpl=="show" || tpl=="production"){
      var hash = window.location.hash;
      if(hash){
          var section = document.querySelector(hash);
          if(section){
              showSection(hash, null);
          }
      }
  }

  // responsive videos
  var typo = document.querySelector('.typo');
  if(typo){
      var embedplayers = ['iframe[src*="youtube.com"]', 'iframe[src*="vimeo.com"]'];

      // Select videos
  
      var fitVids = typo.querySelectorAll(embedplayers.join(","));

      // If there are videos on the page...
      if (fitVids.length) {
          // Loop through videos
          for (var ep = 0; ep < fitVids.length; ep++) {
          // Get Video Information
          var fitVid = fitVids[ep];
          var width = fitVid.getAttribute("width");
          var height = fitVid.getAttribute("height");
          var aspectRatio = height / width;   
          var parentDiv = fitVid.parentNode;

          // Wrap it in a DIV
          var div = document.createElement("div");
          div.className = "videoWrapper";
          div.style.paddingBottom = aspectRatio * 100 + "%";
          parentDiv.insertBefore(div, fitVid);
          fitVid.remove();
          div.appendChild(fitVid);

          // Clear height/width from fitVid
          fitVid.removeAttribute("height");
          fitVid.removeAttribute("width");
          }
      }
  }

    // autohidepastshows (page saison)
    if(autohidepastshows != undefined){
        const button = document.querySelector('.futures');
        button.textContent = "Afficher les spectacles passés";
        button.classList.add('hiddenshows');
        setShowsVisibility(false);
        
        button.addEventListener('click', function(e) {
            if(button.classList.contains('hiddenshows')){
                setShowsVisibility(true);
                button.textContent = "Masquer les spectacles passés";
                button.classList.remove('hiddenshows');
            } else {
                setShowsVisibility(false);
                button.textContent = "Afficher les spectacles passés";
                button.classList.add('hiddenshows');
            }
        })
        // autohidepastshows
    }
    function setShowsVisibility(visibility){
        const monthshows = document.querySelectorAll(".monthshows");
        monthshows.forEach(month => {
            const pastshows = month.querySelectorAll(".tpl-show.past");
            const futuresshows = month.querySelectorAll(".tpl-show.future");
            if(visibility){
                month.style.display = "block";
                pastshows.forEach(show => {
                    show.closest("li").style.display = "block";
                });
            } else {
                if(futuresshows.length == 0) {
                    month.style.display = "none";
                }
                pastshows.forEach(show => {
                    show.closest("li").style.display = "none";
                });
            }
        });
    }

})();

